import image from "./res/55.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <section className="App-header">
        <figure>
          <h1>My Sweet Poet</h1>
          <p>
            i fear the unknown, like most people do. <br />
            unsurprisingly why i try to run <br />
            scripts in my head of how the future should look like. <br />
            the roads to take or exits to make. <br />
            but days go easy with you, <br />
            i can let go of the worries, <br />
            with you i'm home. <br />
            <br />
            in a world where people look to stare, <br />
            you took my hand and it felt like i don't have <br />
            to try to look back or shamefully look down. <br />
            you know just what to do, <br />
            days go easy with you. <br />
            <br />
            you know my good days and take notice
            <br />
            when the light gets dark. <br />
            and through all the magnified mistakes, <br />
            you assure me that it's okay, <br />
            the kind reassurance bringing me back to what's true, <br />
            i have you. <br />
            <br />
            and i don't think i can even remember all the things you did for me
            (though i wish i could) but i'm a better human because of you,
            <br />
            and everyday, i don't run out of the things i'm grateful for having
            spent these last five years with you. the days just go easy with
            you. <br />
            i still look at you sometimes just amazed of how blessed i am to
            have you. <br />
            you know i love you, and i always will.
            <br />
          </p>
        </figure>
      </section>
      <div stle={{ border: "50px", align: "center" }}>
        <img src={image} alt="" height="300" />
      </div>
    </div>
  );
}

export default App;
